<template>
  <div id="base-plan" class="mb-10">
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Base Plan" active>
            <b-card-text>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group form-inline-flex ">
                    <label class="label1" for="">Show schedules for</label>
                    <input
                      type="number"
                      v-model="no_of_days"
                      class="form-control schedule-input"
                    />
                    <label class="label2" for="">Days</label>
                  </div>
                </div>
              </div>
              <div class="row" v-if="currentUser.u.roles == 'admin'">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Weekday</label>
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      v-model="plan.day"
                    >
                      <option value="">Select weekday</option>
                      <option value="all">all</option>
                      <option v-for="(w, i) in weekdays" :key="i" :value="w">{{
                        w
                      }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Start Time</label>
                    <input
                      type="time"
                      v-model="plan.start_time"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">End Time</label>
                    <input
                      type="time"
                      class="form-control"
                      v-model="plan.end_time"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Interval (minutes)</label>
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      v-model="plan.interval"
                    />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">No. of Applicants</label>
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      v-model="plan.no_of_applicants"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <button
                    class="btn btn-outline-info mt-8"
                    @click="planpush(1)"
                    v-if="add_status == 0 && plan.day != 'all'"
                  >
                    Add
                  </button>
                  <button
                    class="btn btn-outline-info mt-8"
                    @click="addallinall()"
                    v-if="plan.day == 'all'"
                  >
                    Add
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="background: white">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Start</th>
                        <th scope="col">Interval</th>
                        <th scope="col">End</th>

                        <th scope="col">No.of Applicants</th>
                        <th scope="col" v-if="currentUser.u.roles == 'admin'">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="5" class="baseplan-header">Sunday</td>
                      </tr>
                      <tr
                        v-for="(item, index) in baseplan.Sunday"
                        :key="'sun_' + index"
                        :class="[item.error == 1 ? 'row_red' : '']"
                        v-show="baseplan.Sunday.length > 0"
                      >
                        <td class="datetime">
                          <input
                            type="time"
                            :id="'Sunday_start_time_' + index"
                            v-model="item.start_time"
                            class="form-control"
                          />
                        </td>
                        <th class="ino">
                          <input
                            :id="'Sunday_interval_' + index"
                            type="number"
                            min="1"
                            v-model="item.interval"
                            class="form-control"
                          />
                        </th>
                        <td class="datetime">
                          <input
                            type="time"
                            :id="'Sunday_end_time_' + index"
                            v-model="item.end_time"
                            class="form-control"
                          />
                        </td>
                        <td class="ino">
                          <input
                            :id="'Sunday_no_of_applicants_' + index"
                            type="number"
                            min="1"
                            class="form-control"
                            v-model="item.no_of_applicants"
                          />
                        </td>
                        <td
                          class="action"
                          v-if="currentUser.u.roles == 'admin'"
                        >
                          <button
                            class="btn btn-danger btn-sm mr-3"
                            @click="removeplan(item.day, index)"
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            v-if="baseplan.Sunday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                          >
                            Add
                          </button>
                          <!-- <i
                            class="fa fa-trash icon-red"
                            @click="removeplan(item.day, index)"
                            aria-hidden="true"
                          ></i>

                          <i
                            class="fa fa-plus icon-green ml-2 "
                            v-if="baseplan.Sunday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                            aria-hidden="true"
                          ></i> -->
                        </td>
                      </tr>
                      <tr
                        v-show="baseplan.Sunday.length == 0"
                        class="text-center"
                      >
                        <td colspan="5">No Data Found</td>
                      </tr>
                      <tr>
                        <td colspan="5" class="baseplan-header">Monday</td>
                      </tr>
                      <tr
                        v-for="(item, index) in baseplan.Monday"
                        :key="'m_' + index"
                        :class="[item.error == 1 ? 'row_red' : '']"
                      >
                        <td class="date">
                          <input
                            type="time"
                            :id="'Monday_start_time_' + index"
                            v-model="item.start_time"
                            class="form-control"
                          />
                        </td>
                        <th class="ino">
                          <input
                            type="number"
                            min="1"
                            :id="'Monday_interval_' + index"
                            v-model="item.interval"
                            class="form-control"
                          />
                        </th>
                        <td class="datetime">
                          <input
                            type="time"
                            :id="'Monday_end_time_' + index"
                            v-model="item.end_time"
                            class="form-control"
                          />
                        </td>

                        <td class="ino">
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            :id="'Monday_no_of_applicants_' + index"
                            v-model="item.no_of_applicants"
                          />
                        </td>
                        <td
                          class="action"
                          v-if="currentUser.u.roles == 'admin'"
                        >
                          <button
                            class="btn btn-danger btn-sm mr-3"
                            @click="removeplan(item.day, index)"
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            v-if="baseplan.Monday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                          >
                            Add
                          </button>
                          <!-- <i
                            class="fa fa-trash icon-red"
                            @click="removeplan(item.day, index)"
                            aria-hidden="true"
                          ></i>

                          <i
                            class="fa fa-plus icon-green ml-2 "
                            v-if="baseplan.Monday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                            aria-hidden="true"
                          ></i> -->
                        </td>
                      </tr>
                      <tr
                        v-show="baseplan.Monday.length == 0"
                        class="text-center"
                      >
                        <td colspan="5">No Data Found</td>
                      </tr>
                      <tr>
                        <td colspan="5" class="baseplan-header">Tuesday</td>
                      </tr>
                      <tr
                        v-for="(item, index) in baseplan.Tuesday"
                        :key="'t_' + index"
                        :class="[item.error == 1 ? 'row_red' : '']"
                      >
                        <td class="date">
                          <input
                            :id="'Tuesday_start_time_' + index"
                            type="time"
                            v-model="item.start_time"
                            class="form-control"
                          />
                        </td>
                        <th class="ino">
                          <input
                            :id="'Tuesday_interval_' + index"
                            type="number"
                            min="1"
                            v-model="item.interval"
                            class="form-control"
                          />
                        </th>
                        <td class="datetime">
                          <input
                            type="time"
                            :id="'Tuesday_end_time_' + index"
                            v-model="item.end_time"
                            class="form-control"
                          />
                        </td>

                        <td class="ino">
                          <input
                            type="number"
                            min="1"
                            :id="'Tuesday_no_of_applicants_' + index"
                            class="form-control"
                            v-model="item.no_of_applicants"
                          />
                        </td>
                        <td
                          class="action"
                          v-if="currentUser.u.roles == 'admin'"
                        >
                          <button
                            class="btn btn-danger btn-sm mr-3"
                            @click="removeplan(item.day, index)"
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            v-if="baseplan.Tuesday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                          >
                            Add
                          </button>
                          <!-- <i
                            class="fa fa-trash icon-red"
                            @click="removeplan(item.day, index)"
                            aria-hidden="true"
                          ></i>

                          <i
                            class="fa fa-plus icon-green ml-2 "
                            v-if="baseplan.Tuesday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                            aria-hidden="true"
                          ></i> -->
                        </td>
                      </tr>
                      <tr
                        v-show="baseplan.Tuesday.length == 0"
                        class="text-center"
                      >
                        <td colspan="5">No Data Found</td>
                      </tr>
                      <tr>
                        <td colspan="5" class="baseplan-header">Wednesday</td>
                      </tr>
                      <tr
                        v-for="(item, index) in baseplan.Wednesday"
                        :key="'w_' + index"
                        :class="[item.error == 1 ? 'row_red' : '']"
                      >
                        <td class="date">
                          <input
                            type="time"
                            :id="'Wednesday_start_time_' + index"
                            v-model="item.start_time"
                            class="form-control"
                          />
                        </td>
                        <th class="ino">
                          <input
                            type="number"
                            min="1"
                            :id="'Wednesday_interval_' + index"
                            v-model="item.interval"
                            class="form-control"
                          />
                        </th>
                        <td class="datetime">
                          <input
                            type="time"
                            :id="'Wednesday_end_time_' + index"
                            v-model="item.end_time"
                            class="form-control"
                          />
                        </td>

                        <td class="ino">
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            :id="'Wednesday_no_of_applicants_' + index"
                            v-model="item.no_of_applicants"
                          />
                        </td>
                        <td
                          class="action"
                          v-if="currentUser.u.roles == 'admin'"
                        >
                          <button
                            class="btn btn-danger btn-sm mr-3"
                            @click="removeplan(item.day, index)"
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            v-if="baseplan.Wednesday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                          >
                            Add
                          </button>
                          <!-- <i
                            class="fa fa-trash icon-red"
                            @click="removeplan(item.day, index)"
                            aria-hidden="true"
                          ></i>

                          <i
                            class="fa fa-plus icon-green ml-2 "
                            v-if="baseplan.Wednesday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                            aria-hidden="true"
                          ></i> -->
                        </td>
                      </tr>
                      <tr
                        v-show="baseplan.Wednesday.length == 0"
                        class="text-center"
                      >
                        <td colspan="5">No Data Found</td>
                      </tr>
                      <tr>
                        <td colspan="5" class="baseplan-header">Thursday</td>
                      </tr>
                      <tr
                        v-for="(item, index) in baseplan.Thursday"
                        :key="'th_' + index"
                        :class="[item.error == 1 ? 'row_red' : '']"
                      >
                        <td class="date">
                          <input
                            type="time"
                            :id="'Thursday_start_time_' + index"
                            v-model="item.start_time"
                            class="form-control"
                          />
                        </td>
                        <th class="ino">
                          <input
                            type="number"
                            min="1"
                            :id="'Thursday_interval_' + index"
                            v-model="item.interval"
                            class="form-control"
                          />
                        </th>
                        <td class="datetime">
                          <input
                            type="time"
                            :id="'Thursday_end_time_' + index"
                            v-model="item.end_time"
                            class="form-control"
                          />
                        </td>

                        <td class="ino">
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            :id="'Thursday_no_of_applicants_' + index"
                            v-model="item.no_of_applicants"
                          />
                        </td>
                        <td
                          class="action"
                          v-if="currentUser.u.roles == 'admin'"
                        >
                          <button
                            class="btn btn-danger btn-sm mr-3"
                            @click="removeplan(item.day, index)"
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            v-if="baseplan.Thursday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                          >
                            Add
                          </button>
                          <!-- <i
                            class="fa fa-trash icon-red"
                            @click="removeplan(item.day, index)"
                            aria-hidden="true"
                          ></i>

                          <i
                            class="fa fa-plus icon-green ml-2 "
                            v-if="baseplan.Thursday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                            aria-hidden="true"
                          ></i> -->
                        </td>
                      </tr>
                      <tr
                        v-show="baseplan.Thursday.length == 0"
                        class="text-center"
                      >
                        <td colspan="5">No Data Found</td>
                      </tr>
                      <tr>
                        <td colspan="5" class="baseplan-header">Friday</td>
                      </tr>
                      <tr
                        v-for="(item, index) in baseplan.Friday"
                        :key="'fr_' + index"
                        :class="[item.error == 1 ? 'row_red' : '']"
                      >
                        <td class="date">
                          <input
                            type="time"
                            :id="'Friday_start_time_' + index"
                            v-model="item.start_time"
                            class="form-control"
                          />
                        </td>
                        <th class="ino">
                          <input
                            type="number"
                            min="1"
                            :id="'Friday_interval_' + index"
                            v-model="item.interval"
                            class="form-control"
                          />
                        </th>
                        <td class="datetime">
                          <input
                            type="time"
                            v-model="item.end_time"
                            :id="'Friday_end_time_' + index"
                            class="form-control"
                          />
                        </td>

                        <td class="ino">
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            :id="'Friday_no_of_applicants_' + index"
                            v-model="item.no_of_applicants"
                          />
                        </td>
                        <td
                          class="action"
                          v-if="currentUser.u.roles == 'admin'"
                        >
                          <button
                            class="btn btn-danger btn-sm mr-3"
                            @click="removeplan(item.day, index)"
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            v-if="baseplan.Friday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                          >
                            Add
                          </button>
                          <!-- <i
                            class="fa fa-trash icon-red"
                            @click="removeplan(item.day, index)"
                            aria-hidden="true"
                          ></i>

                          <i
                            class="fa fa-plus icon-green ml-2 "
                            v-if="baseplan.Friday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                            aria-hidden="true"
                          ></i> -->
                        </td>
                      </tr>
                      <tr
                        v-show="baseplan.Friday.length == 0"
                        class="text-center"
                      >
                        <td colspan="5">No Data Found</td>
                      </tr>
                      <tr>
                        <td colspan="5" class="baseplan-header">Saturday</td>
                      </tr>
                      <tr
                        v-for="(item, index) in baseplan.Saturday"
                        :key="'sat_' + index"
                        :class="[item.error == 1 ? 'row_red' : '']"
                      >
                        <td class="date">
                          <input
                            type="time"
                            :id="'Saturday_start_time_' + index"
                            v-model="item.start_time"
                            class="form-control"
                          />
                        </td>
                        <th class="ino">
                          <input
                            type="number"
                            min="1"
                            :id="'Saturday_interval_' + index"
                            v-model="item.interval"
                            class="form-control"
                          />
                        </th>
                        <td class="datetime">
                          <input
                            type="time"
                            :id="'Saturday_end_time_' + index"
                            v-model="item.end_time"
                            class="form-control"
                          />
                        </td>

                        <td class="ino">
                          <input
                            type="number"
                            min="1"
                            class="form-control"
                            :id="'Saturday_no_of_applicants_' + index"
                            v-model="item.no_of_applicants"
                          />
                        </td>
                        <td
                          class="action"
                          v-if="currentUser.u.roles == 'admin'"
                        >
                          <button
                            class="btn btn-danger btn-sm mr-3"
                            @click="removeplan(item.day, index)"
                          >
                            Delete
                          </button>
                          <button
                            class="btn btn-success btn-sm"
                            v-if="baseplan.Saturday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                          >
                            Add
                          </button>
                          <!-- <i
                            class="fa fa-trash icon-red"
                            @click="removeplan(item.day, index)"
                            aria-hidden="true"
                          ></i>

                          <i
                            class="fa fa-plus icon-green ml-2 "
                            v-if="baseplan.Saturday.length - 1 == index"
                            @click="planpush(2, item, item.day)"
                            aria-hidden="true"
                          ></i> -->
                        </td>
                      </tr>
                      <tr
                        v-show="baseplan.Saturday.length == 0"
                        class="text-center"
                      >
                        <td colspan="5">No Data Found</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="btn- btn-smplan-fixed"
                v-if="currentUser.u.roles == 'admin'"
              >
                <button
                  ref="kt_submit_base_plan"
                  class="btn btn-sm btn-warning mt-4 mb-4 mr-5"
                  @click="goback()"
                >
                  Go back
                </button>
                <button
                  ref="kt_submit_base_plan"
                  vi
                  class="btn btn-sm btn-success mt-4 mb-4 mr-3"
                  @click="saveBasePlans()"
                >
                  Save
                </button>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Holidays">
            <b-card-text>
              <div class="row" v-if="currentUser.u.roles == 'admin'">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Date <span>*</span></label>
                    <input
                      type="date"
                      required
                      v-model="holiday.start"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Remarks</label>
                    <input
                      type="text"
                      required
                      class="form-control"
                      v-model="holiday.remarks"
                    />
                  </div>
                </div>
                <div
                  class="col-md-2"
                  style="margin-top: 2%;
"
                >
                  <div class="form-group">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        required
                        v-model="holiday.set_time"
                        class="form-check-input"
                      />
                      <label
                        class="form-check-label"
                        for="invalidCheck"
                        style="margin-top:12%"
                      >
                        Set Time
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-md-2" v-if="holiday.set_time">
                  <div class="form-group">
                    <label for="">Start time</label>
                    <input
                      type="time"
                      required
                      class="form-control"
                      v-model="holiday.start_time"
                    />
                  </div>
                </div>
                <div class="col-md-2" v-if="holiday.set_time">
                  <div class="form-group">
                    <label for="">End time</label>
                    <input
                      type="time"
                      required
                      class="form-control"
                      v-model="holiday.end_time"
                    />
                  </div>
                </div>

                <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="">End date</label>
                    <input
                      type="date"
                      class="form-control"
                      v-model="holiday.end"
                    />
                  </div>
                </div> -->

                <div class="col-md-1">
                  <button
                    class="btn btn-sm btn-outline-info mt-8"
                    @click="pushHoliday"
                  >
                    Add
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <!-- <h2>Holiday List</h2> -->
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>Remarks</th>
                        <th>Start time</th>
                        <th>End time</th>
                        <th v-if="currentUser.u.roles == 'admin'">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(holiday, i) in getHolidays" :key="i">
                        <td>{{ dateFormat(holiday.holiday_date) }}</td>
                        <td>{{ holiday.remarks }}</td>
                        <td>{{ holiday.start_time }}</td>
                        <td>{{ holiday.end_time }}</td>
                        <td v-if="currentUser.u.roles == 'admin'">
                          <i
                            class="fa fa-trash icon-red"
                            aria-hidden="true"
                            @click="removeHoliday(i, holiday)"
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  STORE_APPOINTMENT_NEW_BASE_PLAN,
  STORE_HOLIDAY,
  FETCH_HOLIDAYS,
  REMOVE_HOLIDAYS,
  FETCH_NEW_BASE_PLAN,
  CHECK_HOLIDAY,
  FETCH_LOCATION_NEW_BASE_PLAN,
  FETCH_LOCATION_HOLIDAYS
} from "@/core/services/store/actions.type";
import moment from "moment";

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  computed: {
    ...mapGetters(["currentUser", "getHolidays"])
  },
  data() {
    return {
      plan: {
        start_time: "",
        end_time: "",
        interval: 0,
        no_of_applicants: 0,
        day: "",
        error: 0,
        is_woking_time: true
      },
      temp_start_time: "",
      temp_end_time: "",
      old_temp_start_time: 0,
      results: [],

      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "New Base Plan",
          route: ""
        }
      ],
      weekdays: moment.weekdays(),
      weekday: "",
      baseplan: {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: []
      },
      no_of_days: 60,
      holidays: [],
      add_status: 1,
      error_status: 1,
      holiday: {
        start: "",
        end: "",
        remarks: "",
        set_time: 0,
        start_time: "",
        end_time: ""
      }
    };
  },
  created() {
    if (this.$route.params.client == "sp") {
      this.getLocationwiseholidays();
      this.getLocationwisebasePlan();
      this.breadCrumbs[1].title =
        this.breadCrumbs[1].title + " (" + this.$route.params.location + ")";
    } else {
      this.getholidays();
      this.fetchbasePlan();
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    pushHoliday() {
      if (this.holiday.start == "") {
        this.$toastr.w("Please enter the required fields");
        return;
      }
      // this.holidays.push(this.holiday);
      let holiday = { ...this.holiday };
      if (holiday.end == "") {
        holiday.end = holiday.start;
      }
      var formData = {
        holiday: holiday,
        check: 0,
        client: this.$route.params.client,
        location_id:
          this.$route.params.client == "sp"
            ? this.$route.params.location_id
            : null
      };

      Swal.fire({
        title: "Are you sure?",
        text: "You want to add this date",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(CHECK_HOLIDAY, formData)
            .then(res => {
              if (res.code == 200) {
                this.$store
                  .dispatch(STORE_HOLIDAY, formData)
                  .then(res => {
                    this.$toastr.s(res.msg);
                    if (this.$route.params.client == "sp") {
                      this.getLocationwiseholidays();
                    } else {
                      this.getholidays();
                    }
                    this.resetHoliday();
                  })
                  .catch(errs => {
                    this.$toastr.e(errs.detail);
                  });
              } else {
                Swal.fire({
                  title: res.msg,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                  cancelButtonText: "No"
                }).then(result => {
                  if (result.isConfirmed) {
                    this.$store
                      .dispatch(STORE_HOLIDAY, formData)
                      .then(res => {
                        this.$toastr.s(res.msg);
                        if (this.$route.params.client == "sp") {
                          this.getLocationwiseholidays();
                        } else {
                          this.getholidays();
                        }
                        this.resetHoliday();
                      })
                      .catch(() => {
                        this.$toastr.e("Something went wrong !");
                      });
                  }
                });
              }
            })
            .catch(() => {
              this.$toastr.e("Something went wrong !");
            });
        }
      });
    },
    fetchbasePlan() {
      this.$store
        .dispatch(FETCH_NEW_BASE_PLAN)
        .then(res => {
          let baseplan = res.data;
          this.no_of_days = res.no_of_days == "" ? 60 : res.no_of_days;

          baseplan.forEach(base => {
            if (base.day == "Sunday" && base.detail != []) {
              this.baseplan.Sunday = base.detail;
            }
            if (base.day == "Monday" && base.detail != []) {
              this.baseplan.Monday = base.detail;
            }
            if (base.day == "Tuesday" && base.detail != []) {
              this.baseplan.Tuesday = base.detail;
            }
            if (base.day == "Wednesday" && base.detail != []) {
              this.baseplan.Wednesday = base.detail;
            }
            if (base.day == "Thursday" && base.detail != []) {
              this.baseplan.Thursday = base.detail;
            }
            if (base.day == "Friday" && base.detail != []) {
              this.baseplan.Friday = base.detail;
            }
            if (base.day == "Saturday" && base.detail != []) {
              this.baseplan.Saturday = base.detail;
            }
          });
        })
        .catch(() => {
          // this.$toastr.e(errs.detail);
        });
    },
    getLocationwisebasePlan() {
      this.$store
        .dispatch(FETCH_LOCATION_NEW_BASE_PLAN, this.$route.params.location_id)
        .then(res => {
          let baseplan = res.data;
          this.no_of_days = res.no_of_days == "" ? 60 : res.no_of_days;

          baseplan.forEach(base => {
            if (base.day == "Sunday" && base.detail != []) {
              this.baseplan.Sunday = base.detail;
            }
            if (base.day == "Monday" && base.detail != []) {
              this.baseplan.Monday = base.detail;
            }
            if (base.day == "Tuesday" && base.detail != []) {
              this.baseplan.Tuesday = base.detail;
            }
            if (base.day == "Wednesday" && base.detail != []) {
              this.baseplan.Wednesday = base.detail;
            }
            if (base.day == "Thursday" && base.detail != []) {
              this.baseplan.Thursday = base.detail;
            }
            if (base.day == "Friday" && base.detail != []) {
              this.baseplan.Friday = base.detail;
            }
            if (base.day == "Saturday" && base.detail != []) {
              this.baseplan.Saturday = base.detail;
            }
          });
        })
        .catch(() => {
          // this.$toastr.e(errs.detail);
        });
    },
    resetHoliday() {
      this.holiday = {
        start: "",
        end: "",
        remarks: "",
        set_time: 0,
        start_time: "",
        end_time: ""
      };
    },
    getholidays() {
      this.$store.dispatch(FETCH_HOLIDAYS);
      return;
    },
    getLocationwiseholidays() {
      this.$store.dispatch(
        FETCH_LOCATION_HOLIDAYS,
        this.$route.params.location_id
      );
      return;
    },
    removeHoliday(i, data) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(REMOVE_HOLIDAYS, data.id)
            .then(res => {
              this.$toastr.s(res.msg);
              if (this.$route.params.client == "sp") {
                this.getLocationwiseholidays();
              } else {
                this.getholidays();
              }
            })
            .catch(errs => {
              this.$toastr.e(errs.detail);
            });
        }
      });
    },
    saveBasePlans() {
      var baseplan = { ...this.baseplan };
      baseplan = Object.values(baseplan);
      let error_status = 0;
      let msg = "Please fill all the value";
      baseplan.filter(word => {
        word.filter((v, i) => {
          if (v.error == 1) {
            if (v.interval == "") {
              let x = `${v.day}_interval_${i}`;
              document.getElementById(x).focus();
              error_status = 1;
              return v;
            }

            if (v.start_time == "") {
              let x = `${v.day}_start_time_${i}`;
              document.getElementById(x).focus();
              error_status = 1;
              return v;
            }
            if (v.end_time == "") {
              let x = `${v.day}_end_time_${i}`;
              document.getElementById(x).focus();
              error_status = 1;
              return v;
            }
            if (v.no_of_applicants == "") {
              let x = `${v.day}_no_of_applicants_${i}`;
              document.getElementById(x).focus();
              error_status = 1;
              return v;
            }
          }
          if (v.interval <= 0) {
            let x = `${v.day}_interval_${i}`;
            document.getElementById(x).focus();
            error_status = 1;
            msg = "Value must be greater than zero";
            return v;
          }
          if (v.no_of_applicants <= 0) {
            let x = `${v.day}_no_of_applicants_${i}`;
            document.getElementById(x).focus();
            msg = "Value must be greater than zero";
            error_status = 1;
            return v;
          }
        });
        return word;
      });
      if (error_status) {
        this.$toastr.w(msg);
        return 0;
      }
      var formData = {
        data: this.baseplan,
        no_of_days: this.no_of_days,
        client: this.$route.params.client,
        location_id:
          this.$route.params.client == "sp"
            ? this.$route.params.location_id
            : null
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (this.error_status) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.disabled = false;
        this.$toastr.w("Please fill all the value");
        return 0;
      }
      this.$store
        .dispatch(STORE_APPOINTMENT_NEW_BASE_PLAN, formData)
        .then(res => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          if (this.$route.params.client == "sp") {
            this.getLocationwisebasePlan();
          } else {
            this.fetchbasePlan();
          }
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    removeplan(weekday, index) {
      // console.log(weekday, index);
      switch (weekday) {
        case "Sunday":
          this.baseplan.Sunday.splice(index, 1);
          break;
        case "Monday":
          this.baseplan.Monday.splice(index, 1);
          break;
        case "Tuesday":
          this.baseplan.Tuesday.splice(index, 1);
          break;
        case "Wednesday":
          this.baseplan.Wednesday.splice(index, 1);
          break;
        case "Thursday":
          this.baseplan.Thursday.splice(index, 1);
          break;
        case "Friday":
          this.baseplan.Friday.splice(index, 1);
          break;
        case "Saturday":
          this.baseplan.Saturday.splice(index, 1);
          break;
        default:
      }
    },
    checkPlan(w, plan, status = 0) {
      let plans = [];
      switch (w) {
        case "Sunday":
          plans = this.baseplan.Sunday;
          break;
        case "Monday":
          plans = this.baseplan.Monday;
          break;
        case "Tuesday":
          plans = this.baseplan.Tuesday;
          break;
        case "Wednesday":
          plans = this.baseplan.Wednesday;
          break;
        case "Thursday":
          plans = this.baseplan.Thursday;
          break;
        case "Friday":
          plans = this.baseplan.Friday;
          break;
        case "Saturday":
          plans = this.baseplan.Saturday;
          break;
        default:
      }
      if (status) {
        return plans.length;
      }
      if (plans.length > 0) {
        return plans.filter(p => p.start_time == plan.start_time);
      } else {
        return -1;
      }
    },
    addallinall() {
      for (const property in this.baseplan) {
        // console.log(property);
        let check_date = this.checkPlan(property, this.plan);
        if (check_date != -1 && check_date.length != 0) {
          this.$toastr.w("Time already added");
          return 0;
        }
      }

      let sun = { ...this.plan };
      let mon = { ...this.plan };
      let tue = { ...this.plan };
      let wed = { ...this.plan };
      let thu = { ...this.plan };
      let fri = { ...this.plan };
      let sat = { ...this.plan };

      sun.day = "Sunday";
      this.baseplan.Sunday.push(sun);
      mon.day = "Monday";
      this.baseplan.Monday.push(mon);
      tue.day = "Tuesday";
      this.baseplan.Tuesday.push(tue);
      wed.day = "Wednesday";
      this.baseplan.Wednesday.push(wed);
      thu.day = "Thursday";
      this.baseplan.Thursday.push(thu);
      fri.day = "Friday";
      this.baseplan.Friday.push(fri);
      sat.day = "Saturday";
      this.baseplan.Saturday.push(sat);
    },
    planpush(val, data = null, weekday = "") {
      // console.log("asoan");
      let plan = null;
      let w = "";
      if (val == 1) {
        plan = { ...this.plan };
        if (
          plan.start_time == "" ||
          plan.end_time == "" ||
          plan.interval == 0 ||
          plan.no_of_applicants == 0 ||
          plan.day == ""
        ) {
          this.$toastr.w("Please fill all the value");
          return 0;
        }
        w = this.plan.day;
      } else {
        data = { ...data };
        let temp_start_time = this.$moment(data.end_time, "HH:mm");
        data.start_time = this.$moment(temp_start_time, "HH:mm").format(
          "HH:mm"
        );
        data.end_time = "";
        plan = data;
        w = weekday;
      }
      let check_date = this.checkPlan(w, plan);
      if (check_date != -1 && check_date.length != 0) {
        this.$toastr.w("Time already added");
        return 0;
      }

      switch (w) {
        case "Sunday":
          this.baseplan.Sunday.push(plan);
          break;
        case "Monday":
          this.baseplan.Monday.push(plan);
          break;
        case "Tuesday":
          this.baseplan.Tuesday.push(plan);
          break;
        case "Wednesday":
          this.baseplan.Wednesday.push(plan);
          break;
        case "Thursday":
          this.baseplan.Thursday.push(plan);
          break;
        case "Friday":
          this.baseplan.Friday.push(plan);
          break;
        case "Saturday":
          this.baseplan.Saturday.push(plan);
          break;
        default:
      }

      // this.reset();
      this.add_status = 1;
    },
    reset() {
      this.plan = {
        start_time: "",
        end_time: "",
        interval: 0,
        no_of_applicants: 0,
        weekday: ""
      };
    },
    goback() {
      this.$router.go(-1);
    }
  },
  watch: {
    "plan.day": function(val) {
      if (val != "") {
        this.add_status = this.checkPlan(val, null, 1);
        return 0;
      }
      this.add_status = 1;
    },
    baseplan: {
      handler(values) {
        for (const property in values) {
          for (let i = 0; i < values[property].length; i++) {
            if (
              values[property][i].start_time == "" ||
              values[property][i].end_time == "" ||
              values[property][i].interval == 0 ||
              values[property][i].no_of_applicants == 0
            ) {
              values[property][i].error = 1;
            } else {
              values[property][i].error = 0;
            }
            if (values[property][i].error == 1) {
              this.error_status = 1;
            } else {
              this.error_status = 0;
            }
          }
        }
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.row_red {
  background-color: #f89494 !important;
}
.base-plan {
  .btn-plan-fixed {
    position: fixed;
    right: 3%;
    top: 89%;
    width: 75%;
    background-color: black;
  }
}
.btn-smplan-fixed {
  position: fixed;
  top: 90%;
  background-color: #ffff;
  width: calc(71vw - 0%);
  left: 24%;
  button {
    float: right;
  }
}
table {
  .baseplan-header {
    background: #b4cfe2;
  }
}
</style>
<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.icon-green {
  color: green;
}
.icon-red {
  color: red;
}
</style>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.action {
  width: 20%;
}
.datetime {
  width: 20%;
}
.ino {
  width: 16%;
}
.form-inline-flex {
  display: inline-flex;
}
.label1 {
  margin-right: 2%;
  margin-top: 2%;
}
.schedule-input {
  width: 27%;
  margin-right: 2%;
}
.label2 {
  margin-top: 2%;
}
</style>
